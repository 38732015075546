import React from "react"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../../utils/htmlSerializer"
import Button from "../shared/button"

import Reveal from "react-reveal/Reveal"

const ContentButtonBlock = ({ data }) => {
  return (
    <Reveal effect="fadeInUp" fraction={0.05}>
      <section className={`grid-container`}>
        <div className={`grid-x`}>
          <div className={`cell`}>
            <RichText
              render={data.primary.text}
              htmlSerializer={htmlSerializer}
            />

            <Button
              text={data.button_text}
              link={data.button_link}
              emoji={data.button_emoji}
              emojiLabel={data.button_emoji_label}
            />
          </div>
        </div>
      </section>
    </Reveal>
  )
}

export default ContentButtonBlock
