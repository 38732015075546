import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import htmlSerializer from "../utils/htmlSerializer"
import PageLink from "../components/shared/pageLink"
import Image from "../components/shared/image"

import ContentBlock from "../components/widgets/contentBlock"
import ContentButtonBlock from "../components/widgets/contentButtonBlock"
import StatsBlock from "../components/widgets/statsBlock"

import Reveal from "react-reveal/Reveal"

export const query = graphql`
  fragment caseStudies on PRISMIC_Case_study {
    _linkType
    _meta {
      id
      type
      uid
    }
    key_question
    theme_color1
    a11y_theme_color
    project_status
    title
    feature_image
    feature_imageSharp {
      extension
      childImageSharp {
        fluid(maxHeight: 452, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
  query CaseStudyQuery($uid: String) {
    prismic {
      allOur_works {
        edges {
          node {
            page_heading
          }
        }
      }
      list: allCase_studys(sortBy: publish_date__order__DESC) {
        edges {
          node {
            ...caseStudies
          }
        }
      }
      bio: allCase_studys(uid: $uid) {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            meta_imageSharp {
              publicURL
            }
            ...caseStudies
            tagline1
            background_image
            background_imageSharp {
              extension
              childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            overlay_image1
            overlay_image1Sharp {
              extension
              childImageSharp {
                fluid(maxWidth: 720, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            cause
            possible_use_cases {
              use_case
            }
            locations
            intro
            site_url {
              _linkType
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            body {
              ... on PRISMIC_Case_studyBodyText {
                __typename
                primary {
                  text
                }
                fields {
                  image
                  image_width
                }
              }
              ... on PRISMIC_Case_studyBodyStatistics_block {
                __typename
                primary {
                  statistics_intro
                  statistics_conclusion
                }
                fields {
                  stat_figure
                  stat_details
                }
              }
              ... on PRISMIC_Case_studyBodyContent_with_button {
                __typename
                primary {
                  text
                  button_text
                  button_link {
                    _linkType
                    ... on PRISMIC_Homepage {
                      _meta {
                        type
                      }
                    }
                    ... on PRISMIC_Our_work {
                      _meta {
                        type
                      }
                    }
                    ... on PRISMIC_Person {
                      _meta {
                        type
                      }
                    }
                    ... on PRISMIC_Support {
                      _meta {
                        type
                      }
                    }
                    ... on PRISMIC_Blog_list {
                      _meta {
                        type
                      }
                    }
                    ... on PRISMIC_Blog_post {
                      _linkType
                      _meta {
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Approach {
                      _meta {
                        type
                      }
                    }
                    ... on PRISMIC_About {
                      _meta {
                        type
                      }
                    }
                    ... on PRISMIC_Basic_page {
                      _linkType
                      _meta {
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Case_study {
                      _linkType
                      _meta {
                        type
                        uid
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  button_emoji
                  button_emoji_label
                }
              }
            }
          }
        }
      }
    }
  }
`

const CaseStudyTemplate = props => {
  const doc = props.data.prismic.bio.edges.slice(0, 1).pop()
  if (!doc) return null

  const currentList = props.data.prismic.list.edges.filter(item => {
    return doc.node.project_status === "In-Progress"
      ? item.node.project_status === "In-Progress"
      : item.node.project_status === "Online" ||
          item.node.project_status === "Archived"
  })
  const otherList = props.data.prismic.list.edges.filter(item => {
    return doc.node.project_status === "In-Progress"
      ? item.node.project_status !== "In-Progress"
      : item.node.project_status === "In-Progress"
  })
  const currentIndex = currentList.findIndex(
    item => item.node._meta.uid === doc.node._meta.uid
  )
  const next = currentList[currentIndex + 1] || otherList[0]

  return (
    <Layout
      pageTitle={
        props.data.prismic.allOur_works.edges[0].node.page_heading[0].text
      }
      parentPage={`/our-work`}
    >
      <SEO
        title={doc.node.meta_title || doc.node.title[0].text}
        image={doc.node.meta_image}
        description={
          doc.node.meta_description ||
          `Case Study about ${doc.node.title[0].text}`
        }
      />

      <Reveal effect="fadeInUp" fraction={0.05}>
        <section
          className={`grid-container c-banner`}
          style={{
            "--theme-color": doc.node.theme_color1,
            "--a11y-theme-color": doc.node.a11y_theme_color,
          }}
          ie-style={`--theme-color: ${doc.node.theme_color1}; --a11y-theme-color: ${doc.node.a11y_theme_color}`}
        >
          <div className={`grid-x align-justify c-banner__container`}>
            <div className={`cell`}>
              <div className={`c-banner__heading`}>
                <h1>
                  <div className={`u-color-theme`}>
                    {doc.node.title[0].text}
                  </div>
                  <span className={`show-for-sr`}> - </span>
                  {doc.node.tagline1}
                </h1>
              </div>
            </div>
          </div>
        </section>
      </Reveal>

      {doc.node.background_image && (
        <section
          style={{ "--theme-color": doc.node.theme_color1 }}
          ie-style={`--theme-color: ${doc.node.theme_color1};`}
        >
          <div className={`c-image-banner`}>
            <div className={`c-image-banner__background`}>
              <Image
                sharpType={"fluid"}
                background
                sharpImage={
                  doc.node.background_imageSharp.childImageSharp.fluid
                }
                alt={doc.node.background_image.alt}
                defaultImage={doc.node.background_image.url}
              />
            </div>
            {doc.node.overlay_image1 && (
              <div className={`c-image-banner__overlay`}>
                <Image
                  sharpType={"fluid"}
                  sharpImage={
                    doc.node.overlay_image1Sharp.childImageSharp.fluid
                  }
                  alt={doc.node.overlay_image1.alt}
                  defaultImage={doc.node.overlay_image1.url}
                />
              </div>
            )}
          </div>
        </section>
      )}

      <Reveal effect="fadeInUp" fraction={0.05}>
        <section className={`grid-container`} style={{ minHeight: "250px" }}>
          <div className={`grid-x grid-margin-x grid-margin-y align-justify`}>
            <div className={`cell medium-7 u-text-semibold`}>
              <RichText
                render={doc.node.intro}
                htmlSerializer={htmlSerializer}
              />
            </div>
            <div className={`cell medium-4`}>
              <h2 className={`u-text-small`}>Cause</h2>
              <p>{doc.node.cause}</p>

              <h2 className={`u-text-small`}>Locations</h2>
              <p>{doc.node.locations}</p>

              <h2 className={`u-text-small`}>Status</h2>
              <p>{doc.node.project_status}</p>
            </div>
          </div>
        </section>
      </Reveal>

      {doc.node.body && doc.node.body.length && (
        <section>
          <h2 className={`show-for-sr`}>Content</h2>
          {doc.node.body.map((section, index) => {
            switch (section.__typename) {
              case "PRISMIC_Case_studyBodyText":
                return <ContentBlock key={index} data={section} />
              case "PRISMIC_Case_studyBodyStatistics_block":
                return <StatsBlock key={index} data={section} />
              case "PRISMIC_Case_studyBodyContent_with_button":
                return <ContentButtonBlock key={index} data={section} />
              default:
                return null
            }
          })}
        </section>
      )}

      {doc.node.possible_use_cases.length > 0 && (
        <Reveal effect="fadeInUp" fraction={0.05}>
          <section className={`grid-container`}>
            <div className={`grid-x`}>
              <div className={`cell`}>
                <h2 className={`u-text-medium`}>Possible Use Cases</h2>
                <ul className={`u-text-medium`}>
                  {doc.node.possible_use_cases.map((item, index) => (
                    <li key={index}>{item.use_case}</li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        </Reveal>
      )}

      {doc.node.site_url && (
        <Reveal effect="fadeInUp" fraction={0.05}>
          <section className={`grid-container`}>
            <div className={`grid-x`}>
              <div className={`cell u-text-medium`}>
                <a
                  rel="noopener noreferrer"
                  href={doc.node.site_url.url}
                  target="_blank"
                >
                  Visit site
                </a>
              </div>
            </div>
          </section>
        </Reveal>
      )}

      <aside className={`c-aside c-aside--collapse grid-container`}>
        <div className={`c-aside__content grid-x`}>
          <Reveal effect="fadeInUp" fraction={0.05}>
            <div
              className={`cell`}
              style={{
                "--theme-color": next.node.theme_color1,
                "--a11y-theme-color": next.node.a11y_theme_color,
              }}
              ie-style={`--theme-color: ${next.node.theme_color1}; --a11y-theme-color: ${next.node.a11y_theme_color}`}
            >
              <PageLink
                item={next.node}
                className={`c-case-study-link c-case-study-link--banner`}
              >
                <div className={`grid-x align-middle align-justify`}>
                  <div className={`cell medium-6`}>
                    <h2 className={`c-case-study-link__cta`}>
                      <span className={`u-text-small`}>Next Project</span>
                    </h2>
                    <h3 className={`u-collapse-top`}>
                      {next.node.key_question}
                    </h3>
                  </div>
                  <div className={`cell medium-5`}>
                    <div className={`c-case-study-link__image`}>
                      <span className={`c-case-study-link__image__container`}>
                        <Image
                          sharpType="fluid"
                          sharpImage={
                            next.node.feature_imageSharp.childImageSharp.fluid
                          }
                          alt={next.node.feature_image.alt}
                          defaultImage={next.node.feature_image.url}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </PageLink>
            </div>
          </Reveal>
        </div>
      </aside>
    </Layout>
  )
}

export default CaseStudyTemplate
