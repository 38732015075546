import React from "react"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../../utils/htmlSerializer"

import Reveal from "react-reveal/Reveal"

const StatsBlock = ({ data }) => {
  return (
    <Reveal effect="fadeInUp" fraction={0.05}>
      <section className={`grid-container`}>
        <div className={`grid-x`}>
          <div className={`cell`}>
            <RichText
              render={data.primary.statistics_intro}
              htmlSerializer={htmlSerializer}
            />

            <div className={`c-stats grid-x grid-margin-x`}>
              {data.fields.map((stat, index) => (
                <div key={index} className={`c-stats__item cell medium-4`}>
                  <span className={`c-stats__item__figure`}>
                    <strong>{stat.stat_figure}</strong>
                  </span>
                  <span className={`c-stats__item__details`}>
                    {stat.stat_details}
                  </span>
                </div>
              ))}
            </div>

            <RichText
              render={data.primary.statistics_conclusion}
              htmlSerializer={htmlSerializer}
            />
          </div>
        </div>
      </section>
    </Reveal>
  )
}

export default StatsBlock
